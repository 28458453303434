import React from 'react';
import styled from 'styled-components';

// Overlay for the dialog
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

// Dialog box container
const DialogBox = styled.div`
  width: 90%;
  max-width: 300px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

// Title for the dialog
const Title = styled.h2`
  font-size: 24px;
  color: #c43246;
  margin-bottom: 15px;
`;

// Message for the dialog
const Message = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
`;

// Close button for the dialog
const CloseButton = styled.button`
  background-color: #c43246;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #a02c3a;
  }
`;

// Define props for the UnsuccessfulDialog component
interface UnsuccessfulDialogProps {
  message?: string;
  onClose: () => void;
}

const UnsuccessfulDialog: React.FC<UnsuccessfulDialogProps> = ({ message, onClose }) => {
  return (
    <Overlay>
      <DialogBox>
        <Title>Oh no!</Title>
        <Message>{message || 'Unfortunately, your flags did not unlock the factory. Please try again!'}</Message>
        <CloseButton onClick={onClose}>Close</CloseButton>
      </DialogBox>
    </Overlay>
  );
};

export default UnsuccessfulDialog;
