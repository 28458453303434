import React, { useEffect } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';

const LandingPageContainer = styled.section`
  height: 120vh;
  background-image: url('/snow_background.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media (max-width: 1024px) {
    background-image: url('/snow_background_small.png');
    background-size: cover;
  }

@media (max-width: 768px) {
    background-image: url('/snow_background_xs.png');
    background-size: cover;
  }
`;

const Snowflake = styled.div`
  position: absolute;
  top: -10vh;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.8;
`;

// Type definition for snowflake style properties
interface SnowflakeStyle {
    left: string;
    scale: number;
    duration: number;
    delay: number;
}

// Adjust number of snowflakes based on screen width
const getSnowflakeCount = (): number => {
    if (window.innerWidth > 1440) return 200;    // Large screens
    if (window.innerWidth > 1024) return 100;    // Medium screens
    return 60;                                  // Small screens
};

// Generate random positions and styles for snowflakes based on screen size
const generateRandomPositions = (): SnowflakeStyle[] => {
    const count = getSnowflakeCount();
    const positions: SnowflakeStyle[] = [];

    for (let i = 0; i < 150; i++) {
        positions.push({
            left: `${Math.random() * 100}vw`,
            scale: Math.random() * 0.5 + 0.5,
            duration: Math.random() * 5 + 5,
            delay: Math.random() * -5
        });
    }

    return positions;
};

const LandingPage: React.FC = () => {
    useEffect(() => {
        const snowflakes = gsap.utils.toArray('.snowflake') as Element[];

        snowflakes.forEach((snowflake) => {
            gsap.to(snowflake, {
                y: "120vh",
                repeat: -1,
                ease: "linear",
                duration: Math.random() * 5 + 5,
                delay: Math.random() * -5,
            });
        });
    }, []);

    return (
        <LandingPageContainer>
            {generateRandomPositions().map((style, index) => (
                <Snowflake
                    key={index}
                    className="snowflake"
                    style={{
                        left: style.left,
                        transform: `scale(${style.scale})`
                    }}
                />
            ))}
        </LandingPageContainer>
    );
};

export default LandingPage;
