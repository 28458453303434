import React from 'react';

interface CodeSnippetProps {
    code: string; // The code snippet to display
}

const CodeSnippet: React.FC<CodeSnippetProps> = ({ code }) => {
    return (
        <div style={{ padding: '16px', backgroundColor: 'black', borderRadius: '8px', marginBottom: '8px' }}>
            <pre style={{ margin: 0, overflowX: 'auto' }}>
                <code style={{ color: 'white' }}>{code}</code>
            </pre>
        </div>
    );
};

export default CodeSnippet;
