import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Hint from "./../Hint";
import RevealOnClick from "./../RevealOnClick";
import SendIcon from "@mui/icons-material/Send";
import Grid from "@mui/material/Grid2";
import { Fade } from "react-awesome-reveal";
import axios from "axios";

const Workstation = styled.div`
  margin-top: 20px;
  height: 300px;
  padding: 20px;
  background-color: black;
  color: red;
  font-size: 14px;
  font-family: Consolas,monaco,monospace; 
  font-style: normal; font-variant: normal; font-weight: 400;
  border-radius: 4px;
  overflow-y: scroll;
  scroll-behavior: smooth;
  white-space: pre-wrap;
  border: 10px groove;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

const WorkstationText = styled.div`
  color: #4AF626;
`;

const WorkstationNoChangeText = styled.div`
  color: #90EE90;
`;

const AboKingdomText = styled.div`
  color: #FF5F1F;
`;

const TaskDescription = styled.div`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;
  margin-top: 28px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const TaskItem = styled.div`
  font-size: 18px;
  margin: 12px 0;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const InputField = styled.input`
  flex: 1; /* Allows the input to take up all available space */
  padding: 10px;
  margin-right: 16px;
  font-size: 16px;
  border: none;
  background: transparent;
  color: white; /* Ensures text is visible on dark backgrounds */
  outline: none; /* Removes the default focus outline */
  border-bottom: 2px solid white;
`;

const Form = styled.form`
  display: flex; /* Align input and button in a row */
  align-items: center;
  margin-top: 20px;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    color: #f4511e; /* Changes icon color on hover */
  }
`;

const ResponseContainer = styled.div`
  margin-top: 20px;
  height: 100px;
  padding: 20px;
  background-color: #353535;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  overflow-y: scroll;
  scroll-behavior: smooth;
`;

const HintTable = styled.table`
  border: 2px groove;
  table-layout: fixed;
  width: 100%;
`;

const HintTableHeader = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #353535;
  color: white;
`
const HintTableRow = styled.tr`
  &:nth-child(even){
    background-color: #f2f2f2;
  }
  &:hover {
    background-color: #ddd;
  }
`

const HintTableData = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`

const Task2v2: React.FC = () => {
  const ELFIE_INTRO = "Hi I'm Elfie, let save christmas together! For starters, I found some irregularities in the system. Would you like to take a look at them?";
  const [isThinking, setIsThinking] = useState(false);
  const [prompt, setPrompt] = useState("");
  const [response, setResponse] = useState<JSX.Element[]>([
    <div>
      <b>Elfie</b> {ELFIE_INTRO}
    </div>
  ]);
  const [workstationText, setWorkstationText] = useState<JSX.Element[]>([
    <div> ____              _           ___  ____  <br />
      / ___|  __ _ _ __ | |_ __ _   / _ \/ ___| <br />
      \___ \ / _` | '_ \| __/ _` | | | | \___ \ <br /> ___) | (_| | | | | || (_| | | |_| |___) |<br />
      |____/ \__,_|_| |_|\__\__,_|  \___/|____/ <br />
      v24.12<br />
      <br />
      Boot sequence changed... <br />
      Warning: New password enforced<br />
      Access Denied!
    </div>]);

  const [chatHistory, setChatHistory] = useState<string[]>([
    "Elfie: " + ELFIE_INTRO
  ])

  useEffect(() => {
    let responseContainerDom = document.getElementById("elfieResponseContainer");
    if (responseContainerDom) {
      responseContainerDom.scrollTop = responseContainerDom.scrollHeight;
    }
  }, [response]);

  useEffect(() => {
    let responseContainerDom = document.getElementById("workstation");
    if (responseContainerDom) {
      responseContainerDom.scrollTop = responseContainerDom.scrollHeight;
    }
  }, [workstationText]);

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (isThinking) {
      return;
    }

    setIsThinking(true);
    if (prompt.trim()) {

      setResponse((oldResponse) => {
        const newArray = oldResponse.map((a) => ({ ...a }));
        if (newArray.length !== 0) {
          newArray.push(<br />);
        }
        newArray.push(
          <div>
            <b>You</b> {prompt.trim()}
          </div>
        );
        newArray.push(<div>Thinking...</div>);
        return newArray;
      });
      setPrompt("");

      axios
        .post(window.TASK_TWO_V2_ENDPOINT, {
          input: Array.from(chatHistory).concat(["User:" + prompt.trim()]).join('\n---\n')
        })
        .then(function (res) {
          const result = res.data;
          console.log(result);
          setChatHistory((oldHistory) => {
            const newArray = Array.from(oldHistory)
            newArray.push("Elfie:" + result.elfie);
            return newArray
          })
          setResponse((oldResponse) => {
            const newArray = oldResponse.map((a) => ({ ...a }));
            newArray.pop();
            let elfieResponse = [];
            let line = result.elfie.split('\n');
            for (let i = 0; i < line.length; i++) {
              if (i === 0) {
                elfieResponse.push(<div><b>Elfie</b> {line[i]}</div>)
              } else {
                elfieResponse.push(<div>{line[i]}</div>)
              }
            }
            newArray.push(
              <div>
                {elfieResponse}
              </div>
            );
            return newArray;
          });
          setWorkstationText((oldWorkstationText) => {
            const newArray = oldWorkstationText.map((a) => ({ ...a }));
            if (result.workstation.length == 0) {
              newArray.push(<br />)
              newArray.push(
                <WorkstationNoChangeText>
                  (no change)
                </WorkstationNoChangeText>
              );
            } else {
              for (let i = 0; i < result.workstation.length; i++) {
                let ABOKINGDOM_PREFIX = "[[AboKingdomConsole]]";
                if (result.workstation[i].startsWith(ABOKINGDOM_PREFIX)) {
                  newArray.push(<br />)
                  newArray.push(
                    <AboKingdomText>
                      {result.workstation[i].slice(ABOKINGDOM_PREFIX.length)}
                    </AboKingdomText>
                  );
                } else {
                  newArray.push(<br />)
                  newArray.push(
                    <WorkstationText>
                      {result.workstation[i]}
                    </WorkstationText>
                  );
                }
              }
            }
            return newArray;
          });
          let responseContainerDom =
            document.getElementById("responseContainer");
          if (responseContainerDom) {
            responseContainerDom.scrollTop = responseContainerDom.scrollHeight;
          }
        })
        .catch(function (error) {
          console.log(error);
          setResponse((oldResponse) => {
            const newArray = oldResponse.map((a) => ({ ...a }));
            newArray.pop();
            newArray.push(
              <div>
                <div><b>Elfie</b> Sorry something went wrong, please try again.</div>
              </div>
            );
            return newArray;
          });
        })
        .finally(() => {
          setIsThinking(false);
        });

      setChatHistory((oldHistory) => {
        const newArray = Array.from(oldHistory)
        newArray.push("User:" + prompt.trim());
        return newArray
      })
    }
  };

  return (
    <Fade triggerOnce={true}>
      <TaskItem>Task 2: Jingle All The Way To Access 🔔</TaskItem>
      <TaskDescription>
        Great news, while you were tackling the microservice, the elves managed
        to ambush Abominable and melt him down to size!
        However, it seems like the elves are locked out of their workstations,
        was the password changed by Abominable!?
      </TaskDescription>
      <TaskDescription>
        Team up with Elfie, North Pole's very own smart* A.I. Debug and figure
        out the new password (flag). Will you rise to the challenge and outsmart the Abominable?
      </TaskDescription>

      <Grid container>
        <Grid size={12}>
          <Workstation id={"workstation"}>{workstationText}</Workstation>
        </Grid>
        <Grid size={12}>
          <Form onSubmit={handleFormSubmit}>
            <InputField
              type="text"
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Talk to Elfie..."
            />

            <IconButton type="button" onClick={handleFormSubmit}>
              <SendIcon
                sx={{
                  color: prompt.trim() ? "white" : "grey", // Grey out when disabled
                }}
                fontSize="medium"
              />
            </IconButton>
          </Form>
          {/* Response Container */}
          <ResponseContainer id={"elfieResponseContainer"}>
            {response}
          </ResponseContainer>
        </Grid>
      </Grid>

      <Hint
        hintText={
          <div>
            <div>The flag is broken up into 3 partial flags and fitted into a template. Partial flags and template are scattered across locations.</div>
            <br />
            <HintTable>
              <HintTableRow>
                <HintTableHeader>Location</HintTableHeader>
                <HintTableHeader>Flag Clue at Location</HintTableHeader>
              </HintTableRow>
              <HintTableRow>
                <HintTableData>Blings and Shimmers!</HintTableData>
                <HintTableData><RevealOnClick text="????????????" /></HintTableData>
              </HintTableRow>
              <HintTableRow>
                <HintTableData>Christmas Flying Transportation</HintTableData>
                <HintTableData><RevealOnClick text="Have you looked at yourself in the mirror?" /></HintTableData>
              </HintTableRow>
              <HintTableRow>
                <HintTableData>What rhythms with OneDrive and Google Drive?</HintTableData>
                <HintTableData><RevealOnClick text="Fancy a peek inside?" /></HintTableData>
              </HintTableRow>
              <HintTableRow>
                <HintTableData>Throne of Terror</HintTableData>
                <HintTableData><RevealOnClick text="I am you and you are me" /></HintTableData>
              </HintTableRow>
            </HintTable>
          </div>


        }
      />
    </Fade>
  );
};

export default Task2v2;
