import React from 'react';
import styled from 'styled-components';
import { Fade, JackInTheBox } from "react-awesome-reveal";

const StorylineBackgroundContainer = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #D5E7F3 20%, #292C4B 80%);
  display: flex;
  justify-content: center;
`;

const ContentContainer = styled.div`
  padding: 100px 24px 36px 24px; 
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 20px;
  color: white;
  max-width: 1100px;
  justify-content: center;
  align-items: center;

  transition: opacity 1s ease-out, transform 1s ease-out;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 50px
  }
`;

const StoryLineSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  padding: 20px;
  border-radius: 20px;
`;

const StoryLineTitle = styled.div`
  font-size: 26px;
  text-align: left;
  width: 100%;
  padding-bottom: 5px;
  font-weight: 700;
  @media (max-width: 1024px) {
    font-size: 18px;
  }
`;

const StoryLineContents = styled.p`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const StoryLineImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StoryLineImage = styled.img`
  height: 350px;
  animation: sneak 1s ease-in-out infinite; 
  border-radius: 16px;

  @keyframes sneak {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(-10px); }
  }

  @media (max-width: 1024px) {
    height: 280px;
  }
`;

const StorylineSection: React.FC = () => {
  return (
    <StorylineBackgroundContainer>
      <ContentContainer>
        <StoryLineImageContainer>
          {/* <StoryLineImage src='/elf_recon_team.png' /> */}
          <StoryLineImage src='/storyline_image.png' />
        </StoryLineImageContainer>
        <JackInTheBox>
          <StoryLineSection>
            <StoryLineTitle>A Christmas Crisis! 🛠️</StoryLineTitle>
            <StoryLineContents>
              The Abominable Snowman hijacked Santa's Gift Factory, sealing it shut behind high-tech defenses. No more naughty or nice list; no one is getting presents this Christmas!
              <br />
              <br />
              Team up with the elves to outsmart Abominable's traps, develop countermeasures and reclaim the factory before time runs out. The fate of the holiday is in your hands! 🧝‍♂️🧝‍♀️
            </StoryLineContents>
          </StoryLineSection>
        </JackInTheBox>
      </ContentContainer>
    </StorylineBackgroundContainer>
  );
};

export default StorylineSection;
