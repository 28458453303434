import { useState } from "react";
import { styled } from '@mui/system';
import Button from '@mui/material/Button';

interface Props {
    text: string; // The code snippet to display
}

const StyledButton = styled(Button)({
    'background-color': '#353535'
  });

const RevealOnClick: React.FC<Props> = ({text}) => {
    const [revealed, setRevealed] = useState(false);

    function revealText() {
        setRevealed(true);
    }

    if (revealed) {
        return <div>{text}</div>
    }

    return <StyledButton variant="contained" onClick={revealText}>Reveal</StyledButton>
}

export default RevealOnClick