import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import SubmissionForm from './SubmissionForm';
import { JackInTheBox } from 'react-awesome-reveal';

const SubmissionBackgroundContainer = styled.div`
  width: 100%;
  background-color: #212343;
  display: flex;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 30px;
`;

const ContentContainer = styled.div`
  padding: 16px; 
  display: grid;
  grid-template-columns: 1fr 1fr; 
  gap: 80px;
  color: white;
  max-width: 1100px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 25px;
  }
`;

const SubmissionFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const SubmissionTitle = styled.div`
  font-size: 26px;
  text-align: left;
  width: 100%;
  padding-bottom: 5px;
  font-weight: 900;
  @media (max-width: 1024px) {
    font-size: 24px;
  }
`;

const SubmissionContents = styled.p`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const SubmissionImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SubmissionImage = styled.img`
  height: 300px;
  border-radius: 16px;
  animation: jump 1s ease-in-out infinite;

  @keyframes jump {
    0%, 100% {
      transform: translateY(0); /* Default position */
    }
    50% {
      transform: translateY(-20px); /* Move up by 5px */
    }
  }

  @media (max-width: 1024px) {
    height: 250px;
  }
`;


interface SubmissionSectionProps {
  setDisplayUnsuccessfulDialog: (display: boolean) => void;
  setDisplaySuccessfulDialog: (display: boolean) => void;
}

const SubmissionSection: React.FC<SubmissionSectionProps> = ({ setDisplayUnsuccessfulDialog, setDisplaySuccessfulDialog }) => {

  return (
    <SubmissionBackgroundContainer>
      <ContentContainer>
        <SubmissionImageContainer>
          <SubmissionImage src='/reindeer_santa.png' />
        </SubmissionImageContainer>
        <JackInTheBox>
          <SubmissionFormContainer>
            <SubmissionTitle>Ready to save Christmas?</SubmissionTitle>
            <SubmissionForm setDisplayUnsuccessfulDialog={setDisplayUnsuccessfulDialog} setDisplaySuccessfulDialog={setDisplaySuccessfulDialog} />
          </SubmissionFormContainer>
        </JackInTheBox>
      </ContentContainer>

    </SubmissionBackgroundContainer>
  );
};

export default SubmissionSection;