import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, colors } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const StyledDialogTitle = styled(DialogTitle)`
  position: relative;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack title and button on small screens */

  .title-text {
    font-size: 28px; /* Set font size for the text */
    font-weight: bold;
    color: #003049; /* Green color for success */
    font-family: 'DancingScript-Bold', sans-serif; /* Apply the custom font */
    text-align: center; /* Center the title text */

    @media (max-width: 600px) {
      font-size: 21px;
    }
  }

  & > button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  padding: 0;
  color: #ffffff;
  font-size: 24px;
  &:hover {
    background-color: #ffffff;
  }
`;

const StyledDialogContent = styled(DialogContent)`
  font-size: 16px;
  color: #003049;
  align-items: center;
  display: flex;
  flex-direction: column;

`;

const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    max-width: 800px; /* Maximum width */
    width: 90%; /* Responsive width */
    height: 90%;
    background-color: #fffff9; /* Background color */
    border: 10px solid #C43246; /* Green border */
    border-radius: 8px; /* Optional rounded corners */
    padding: 10px; /* Optional padding */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
`;

const StyledListItem = styled.li`
  margin-bottom: 2px;
`;


// Define props for the CongratulatoryPage component
interface CongratulatoryPageProps {
  onClose: () => void;
}

const CongratulatoryPage: React.FC<CongratulatoryPageProps> = ({ onClose }) => {
  return (
    <StyledDialog fullScreen open={true} onClose={onClose}>
      <StyledDialogTitle>
        <CloseButton onClick={onClose} aria-label="close">
          <CloseIcon sx={{ color: 'black' }} />
        </CloseButton>
      </StyledDialogTitle>
      <StyledDialogTitle>
        <span className="title-text">✨ You are a Holiday Hero! ✨</span>
        {/* <CloseButton onClick={onClose} aria-label="close">
          <CloseIcon sx={{ color: 'black' }} />
        </CloseButton> */}
      </StyledDialogTitle>
      <StyledDialogContent>
        <p style={{ textAlign: 'center' }}>
          Nice job! You have restored access to Santa's Gift Factory! Your skillful navigation through the digital maze and clever problem-solving have ensured that the elves can resume toy production just in time for Christmas.
          Enjoy the well-earned cheer — you’ve earned a permanent spot on the Nice List!
        </p>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', paddingBottom: '20px' }}>
          <img
            src="/mini_challenge_badge_specimen.png"
            alt="Mini_challenge_badge_specimen"
            style={{
              width: '100%',
              maxWidth: '300px',
              marginBottom: '16px',
              marginTop: '16px',
              borderRadius: '10px'
            }}
          />
          <span style={{ textAlign: 'center' }}>
            Download your badge&nbsp;
            <a
              href="https://form.gov.sg/6746e83159f1f6be5abec841"  // Replace with your actual image link
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
          </span>
        </div>
        <StyledDialogTitle>
          <span className="title-text">Did you know?</span>
        </StyledDialogTitle>
        <ul style={{ margin: '16px 0', paddingLeft: '20px', listStyle: 'none' }}>
          In CSIT, we leverage OpenTelemetry (OTEL) and distributed tracing to gain critical insights into application behavior and network traffic. These tools are essential for several reasons:
          <br /><br />
          <li style={{ marginBottom: '10px' }}>
            <span role="img" aria-label="Christmas tree">🎄</span> Analysing Traffic Across Services:  OTEL and distributed tracing enable precise monitoring of interactions across distributed systems, providing a comprehensive view of service dependencies, latency, and potential bottlenecks. By observing traffic flows within our architecture, we gain valuable insights into service interactions, ensuring optimised performance and robust security in complex environments.
          </li>
          <li style={{ marginBottom: '10px' }}>
            <span role="img" aria-label="Santa Claus">🎅</span> Enhancing Root Cause Analysis: You cannot protect what you cannot see, visibility over our architecture empowers our engineers to troubleshoot errors effectively,  detect issues within secure channels, and ensure that interactions between services align with expected behavior—ultimately improving system resilience and security.
          </li>
        </ul>
        <ul style={{ margin: '16px 0', paddingLeft: '20px', listStyle: 'none' }}>
          In CSIT, we leverage artificial intelligence to drive the development of intelligent tools and services. Crafting precise and effective prompts is essential for several key reasons:
          <br /><br />
          <li style={{ marginBottom: '10px' }}>
            <span role="img" aria-label="Snowman">⛄</span> Prompt engineering helps us to frame questions and tasks in a way that extracts highly relevant information, which in turn helps inform decision-making and product development. With well-crafted prompts, we can guide AI models to provide analysis on user behavior, preferences, and needs. This is especially valuable when analyzsing complex data or interpreting nuanced information, where the right prompts can lead to better model performance and higher-quality outputs.
          </li>
        </ul>
        <StyledDialogTitle>
          <span className="title-text">Relevant jobs</span>
        </StyledDialogTitle>

        <ul style={{ margin: '10px 0', paddingLeft: '0px', listStylePosition: 'inside', listStyleType: 'none' }}>
          <li style={{ marginBottom: '10px' }}>
            <span role="img" aria-label="Christmas tree">🎄</span> Software Engineering (See more <a
              href="https://jobs.lever.co/csit?commitment=Full-time&department=Software%20Engineering"  // Replace with your actual image link
              style={{ textDecoration: 'underline' }}
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>!)
          </li>
          {/* <li style={{ marginBottom: '10px' }}>
            <span role="img" aria-label="Christmas tree">🎄</span> Artificial Intelligence Engineer
          </li> */}
        </ul>
        {/* <StyledDialogTitle>
          <span className="title-text">Feedback</span>
        </StyledDialogTitle>
        <span>  Do provide us with your feedback in this <a
          href="https://form.gov.sg/6746e83159f1f6be5abec841"  // Replace with your actual image link
          style={{ textDecoration: 'underline' }}
          target="_blank"
          rel="noopener noreferrer"
        >
          link
        </a>. Thank you for participating!</span> */}

      </StyledDialogContent>
    </StyledDialog>
  );
}

export default CongratulatoryPage;
