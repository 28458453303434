import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Task1 from './tasks/Task1'
import Task2 from './tasks/Task2'
import Task2v2 from './tasks/Task2v2'

const Container = styled.div`
    padding: 24px 24px 24px 24px;
    color: white; 
    background: linear-gradient(to bottom, #292C4B 10%, #212343 90%)
`;

const ContentContainer = styled.div`
    max-width: 1100px;
    margin: 0 auto;\
`

const TaskList = styled.div`
    width: 100%;
    margin-top: 16px;
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  background-color: white; /* Change color as needed */
  margin: 40px 0; /* Adjust spacing */
`;




const Challenges: React.FC = () => {
  return (
    <Container>
      <ContentContainer>
        {/* <InstructionsTitle>Instructions</InstructionsTitle>
                <TaskDescription>Set up your environment:
                    <br />
                    xxx
                </TaskDescription>
                <Divider /> */}
        <TaskList>
          <Task1 />
          <Divider />
          {/* <Task2 />
          <Divider /> */}
          <Task2v2 />
        </TaskList>
      </ContentContainer>
    </Container>
  );
};

export default Challenges;
