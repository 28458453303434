import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import LandingPage from './components/LandingPage';
import StorylineSection from './components/StorylineSection';
import Challenges from './components/Challenges';
import SubmissionSection from './components/SubmissionSection';
import Footer from './components/Footer';
import UnsuccessfulDialog from './components/UnsuccessfulDialog';
import CongratulatoryPage from './components/CongratulatoryPage';

function App() {
  const [displayUnsuccessfulDialog, setDisplayUnsuccessfulDialog] = useState(false)
  const [displaySuccessfulDialog, setDisplaySuccessfulDialog] = useState(false)

  return (
    <div className="App">
      {displayUnsuccessfulDialog && <UnsuccessfulDialog onClose={() => { setDisplayUnsuccessfulDialog(false) }} />}
      {displaySuccessfulDialog && <CongratulatoryPage onClose={() => { setDisplaySuccessfulDialog(false) }} />}
      <Navbar />
      <LandingPage />
      <StorylineSection />
      <Challenges />
      <SubmissionSection setDisplayUnsuccessfulDialog={setDisplayUnsuccessfulDialog} setDisplaySuccessfulDialog={setDisplaySuccessfulDialog} />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
