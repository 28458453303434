import React, { useState } from 'react';
import styled from 'styled-components';
import UnsuccessfulDialog from './UnsuccessfulDialog';

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
`;

const SubmissionDescription = styled.div`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const Label = styled.label`
  font-size: 16px;
  margin-top: 16px;
  margin-bottom: 4px;
`;

const Input = styled.input`
  padding: 8px;
  margin-top: 4px;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
  width: 96%;
`;

const SubmitButton = styled.button<{ disabled: boolean }>`
  width: 100%;
  padding: 10px;
  background-color: ${({ disabled }) => (disabled ? '#ccc' : '#C43246')};
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  margin-top: 36px;
  
  &:hover {
    background-color: ${({ disabled }) => (disabled ? '#ccc' : '#B1283B')};
  }
`;

interface SubmissionFormProps {
  setDisplayUnsuccessfulDialog: (display: boolean) => void;
  setDisplaySuccessfulDialog: (display: boolean) => void;
}

const SubmissionForm: React.FC<SubmissionFormProps> = ({ setDisplayUnsuccessfulDialog, setDisplaySuccessfulDialog }) => {
  const [task1Flag, setTask1Flag] = useState('');
  const [task2Flag, setTask2Flag] = useState('');
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!task1Flag || !task2Flag) return;

    setLoading(true);

    try {
      console.log('API URL:', process.env.REACT_APP_API_URL);
      console.log("Current Environment:", process.env.NODE_ENV);
      const url = window.FLAG_SUBMISSION_ENDPOINT;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          flag1: task1Flag.trim(),
          flag2: task2Flag.trim(),
        }),
      });

      if (response.ok) {
        const result = await response.json();
        if (result.flag1 && result.flag2) {
          setDisplaySuccessfulDialog(true);
        } else {
          setDisplayUnsuccessfulDialog(true);
        }
      } else {
        console.error('API error:', response.statusText);
        setDisplayUnsuccessfulDialog(true);
      }
    } catch (error) {
      console.error('Network error:', error);
      setDisplayUnsuccessfulDialog(true);
    } finally {
      setLoading(false);
      setTask1Flag('');
      setTask2Flag('');
    }
  };

  return (
    <FormContainer onSubmit={handleSubmit}>
      <SubmissionDescription>
        Have your flags ready? Enter them below!
      </SubmissionDescription>
      <Label>Task 1 Flag</Label>
      <Input
        type="text"
        id="task1"
        value={task1Flag}
        placeholder="E.g. CSIT{xxx}"
        disabled={loading}
        onChange={(e) => setTask1Flag(e.target.value)}
      />
      <Label>Task 2 Flag</Label>
      <Input
        type="text"
        id="task2"
        value={task2Flag}
        disabled={loading}
        onChange={(e) => setTask2Flag(e.target.value)}
        placeholder="E.g. CSIT{xxx}"
      />
      <SubmitButton disabled={loading || !task1Flag || !task2Flag}>
        {loading ? 'Submitting...' : 'Submit'}
      </SubmitButton>
    </FormContainer>
  );
};

export default SubmissionForm;
