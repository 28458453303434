import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Hint from "./../Hint";
import CodeSnippet from "./../CodeSnippet";
import { Fade } from "react-awesome-reveal";

const TaskDescription = styled.div`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;
  margin-top: 28px;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const TaskItem = styled.div`
  font-size: 18px;
  margin: 12px 0;
  font-weight: 700;

  @media (max-width: 1024px) {
    font-size: 16px;
  }
`;

const TaskStep = styled.div`
  font-size: 18px;
  text-align: left;
  line-height: 1.5;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Task1: React.FC = () => {
  const configLinkOnclick = (config: string) => {
    window.open((window as { [key: string]: any })[config] as string);
  };

  return (
    <Fade triggerOnce={true}>
      <TaskItem>Task 1: Ho-Ho-h-OTEL 🔎</TaskItem>
      <TaskDescription>
        Reconnaisance reveals Santa’s microservice-based Gift Factory system,
        guarded by the GateKeeper service, has been sabotaged. The intricate
        system powering toy production inventory and orders have been
        disconnected. Grab your tools and join the elves — it’s time to restore
        the factory to its former glory!
      </TaskDescription>
      <TaskDescription>
        The elves have delegated you the task of <b>building</b> and{" "}
        <b>deploying</b> the Order Service to{" "}
        <b>retrieve the toy production key</b>, which is sent from the Toy
        Production Service – they will handle the rest. <b>Remember, the Toy
          Production service endpoint will only accept requests and do its job
          with the correct secret input string, which is for you to find out!</b>{" "}
        Use OpenTelemetry to trace communication disruptions and iron out the quirks.

      </TaskDescription>
      <TaskDescription>
        The elves found this architecture diagram below to help you out:
        <br />
        <br />
        <div style={{ textAlign: "center" }}>
          <img
            src="architecture_diagram_dark.png"
            alt="Architecture Diagram"
            style={{ width: "100%", borderRadius: "16px" }}
          />
        </div>
      </TaskDescription>
      <TaskStep>Order Service (build this!):</TaskStep>
      <CodeSnippet
        code={`API endpoint: POST /api/toyProductionKey\n\nRequest Body:\n\n{\n  toyProductionKey: string\n}`}
      />
      <TaskStep>GateKeeper Service (send your request here):</TaskStep>
      <CodeSnippet
        code={`API endpoint: POST https://dec-2024-mini-challenge.csit-events.sg/api/gatekeeper/access\n\nRequest Body:\n\n{\n  orderServiceHostOrIpAddress: string      //include your port number if applicable e.g. 123.123.123.123:8888,\n  secretInput: string\n} \n`}
      />
      <TaskStep>
        Link to Kibana dashboard:{" "}
        <a
          href="#"
          onClick={() => configLinkOnclick("KIBANA_DASHBOARD")}
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
      </TaskStep>
      <TaskStep>
        Request a Kibana account:{" "}
        <a
          href="#"
          onClick={() => configLinkOnclick("KIBANA_ACCOUNT_CREATE_ENDPOINT")}
          rel="noopener noreferrer"
          style={{ color: "white" }}
        >
          here
        </a>
      </TaskStep>
      <TaskStep>Resources:</TaskStep>
      <ul>
        <li style={{ marginBottom: "8px" }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://youtu.be/f-jZoq0f9-0?si=7lOSnnxBqRU3PXq-"
          >
            View Backend Logs using Kibana & Elasticsearch Tutorial
          </a>
        </li>
        <li style={{ marginBottom: "8px" }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://youtu.be/DQk3zJlY-eE?si=NVB15O56NXx5gR9x"
          >
            Deploy a simple web server on Heroku to accept requests in 5 minutes
          </a>
        </li>
        <li style={{ marginBottom: "8px" }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://www.youtube.com/watch?v=F7R8dEin6ZY"
          >
            Deploy a simple web server on GCP Compute Engine to accept requests (free tier available for you to play around with)
          </a>
        </li>
        <li style={{ marginBottom: "8px" }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://www.youtube.com/watch?v=5gSG5jwOJSY"
          >
            Deploy a simple web server on AWS EC2 to accept requests (free tier available for you to play around with)
          </a>
        </li>
        <li style={{ marginBottom: "8px" }}>
          <a
            style={{ color: "white" }}
            target="_blank"
            href="https://www.youtube.com/watch?v=8mBmLDbpIH8"
          >
            Sending HTTP requests easily with Postman
          </a>
        </li>
      </ul>

      <Hint hintText="Look closer, look deeper :)" />
    </Fade>
  );
};

export default Task1;
