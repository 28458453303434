import React from 'react';
import styled from 'styled-components';

// Navbar container with sticky positioning
const NavbarContainer = styled.nav`
  padding: 5px 40px;
  background-color: #282B4B;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0;
  z-index: 1000;

  /* Adjust padding for smaller screens */
  @media (max-width: 768px) {
    padding: 5px 10px;
  }
`;

// Logo styling with hover effect
const Logo = styled.img`
  cursor: pointer;
  width: 100px;
  transition: transform 0.3s ease, opacity 0.3s ease;

  &:hover {
    transform: scale(1.2);
    opacity: 0.9;
  }

  /* Resize logo on smaller screens */
  @media (max-width: 768px) {
    width: 80px;
  }
  
  @media (max-width: 480px) {
    width: 60px;
  }
`;

// Flex container for icons on the right
const RightIcons = styled.div`
  display: flex;
  gap: 10px;

  /* Reduce gap for smaller screens */
  @media (max-width: 768px) {
    gap: 5px;
  }
`;

const Icon = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
  transition: transform 0.3s ease, opacity 0.3s ease;

  &:hover {
    transform: scale(1.2);
    opacity: 0.9;
  }

  /* Resize icons on smaller screens */
  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;

const Navbar: React.FC = () => {
  return (
    <NavbarContainer>
      <a href="https://csit.gov.sg" target="_blank" rel="noopener noreferrer">
        <Logo src='/csit_logo.png' />
      </a>
      <RightIcons>
        <a href="https://www.facebook.com/csitsg" target="_blank" rel="noopener noreferrer">
          <Icon src="/facebook_icon.png" alt="Icon FB" />
        </a>
        <a href='https://www.instagram.com/csitsg' target="_blank" rel="noopener noreferrer">
          <Icon src="/instagram_icon.png" alt="Icon IG" />
        </a>
        <a href='https://www.linkedin.com/company/csitsg/posts/?feedView=all'>
          <Icon src="/linkedin_icon.png" alt="Icon LI" />
        </a>

      </RightIcons>
    </NavbarContainer>
  );
};

export default Navbar;
