import * as React from 'react';
import Accordion, { AccordionSlots } from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import styled from '@emotion/styled';

interface HintProps {
    hintText: string | React.ReactElement;
}

const StyledAccordion = styled(Accordion)`
  margin-top: 40px;
  margin-bottom: 40px;
  border-radius: 8px;
  overflow: hidden;

  &.MuiAccordion-root {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
`;

const StyledTypography = styled(Typography)`
    font-weight: 400;
`

const Hint: React.FC<HintProps> = ({ hintText }) => {
    const [expanded, setExpanded] = React.useState(false);

    const handleExpansion = () => {
        setExpanded((prevExpanded) => !prevExpanded);
    };

    return (
        <StyledAccordion
            expanded={expanded}
            onChange={handleExpansion}
            slots={{ transition: Fade as AccordionSlots['transition'] }}
            slotProps={{ transition: { timeout: 400 } }}
            sx={[
                expanded
                    ? {
                        '& .MuiAccordion-region': {
                            height: 'auto',
                        },
                        '& .MuiAccordionDetails-root': {
                            display: 'block',
                        },
                    }
                    : {
                        '& .MuiAccordion-region': {
                            height: 0,
                        },
                        '& .MuiAccordionDetails-root': {
                            display: 'none',
                        },
                    },
            ]}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
            >
                <StyledTypography>Need a hint? Click to see it!</StyledTypography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    {hintText}
                </Typography>
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default Hint;
